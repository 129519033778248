@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  direction: rtl;
  margin: 0;
  padding: 0;
  color: #212F61;
  font-family: 'Noto Sans Hebrew', sans-serif;
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  height: inherit;
}

.layout{
  background: linear-gradient(248deg, #2068D5 23.72%, #4DA1E4 62.66%, #50DFFF 97.71%);
}

.loan-application-status {
  background: linear-gradient(86deg, #ADDCFF -14.83%, #EAF6FF 39.64%, #EAF6FF 93.5%);
}

.direction-rtl {
  direction: rtl;
}

.direction-ltr {
  direction: ltr;
}

@layer base {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus{
    -webkit-box-shadow: 0 0 0 1000px white inset;
  }

  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button,
  input[type='number'] {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield !important;
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
    opacity: 0; /* Hide the default calendar icon in Webkit browsers */
    position: absolute;
    width: 100%;
  }
  input[type="date"]::-webkit-datetime-edit-year-field,
  input[type="date"]::-webkit-datetime-edit-month-field,
  input[type="date"]::-webkit-datetime-edit-day-field,
  input[type="date"]::-webkit-datetime-edit-text {
    display: none;
  }

  input[type="date"]::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    background-image: url('assets/icons/calendar.svg'); /* Use your custom icon */
    background-size: 18px 18px; /* Adjust size */
    width: 18px;
    height: 18px;
  }
}

@layer components {
  .collapse-primary {
    @apply font-medium text-sm leading-5;
  }
  .collapse-info {
    @apply text-buttonBlue text-small font-semibold text-right;
  }
  .collapse-info path {
    @apply stroke-buttonBlue;
  }
}

.last\:form-mb-20 form:last-child {
  @apply mb-20;
}

.video-slider::-webkit-slider-thumb {
  @apply appearance-none w-4 h-4 bg-white rounded-full cursor-pointer; /* Thumb styling */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
