:where([autocomplete=one-time-code]) {
    --otp-digits: 6; /* length */
    --otc-ls: 46px;
    --otc-gap: 0.8;
    --_otp-bgsz: calc(52.5px);
    --otc-bg:  #EEE;
    --otc-cc: #333;
    --otc-fz: 14px;
    --otc-digits: 20;
    --otc-pb: 1ch;
    all: unset;


    background: linear-gradient(90deg,
    var(--otc-bg, #EEE) calc(46px),
    transparent 0
    ) 0 0 / var(--_otp-bgsz) 100%;
    caret-color: var(--otc-cc, #333);
    font-family: ui-monospace, monospace;
    font-size: var(--otc-fz, 2.5em);
    inline-size: calc(var(--otc-digits) * var(--_otp-bgsz));
    letter-spacing: var(--otc-ls);
    padding-block: var(--otc-pb, 1ch);
    padding-inline-start: calc(((var(--otc-ls) - 1ch) / 2) * var(--otc-gap));
}
